import { Link } from "react-router-dom"
import Button from "../../commonComponent/Button/Button"
import Image from "../../commonComponent/Image/Image"
import './AppointBanner.css'


function AppointBanner(props) {
  return (
    <div className="appointBannerSection">
        <div className="appointBannerImgDiv">
            {/* <Image src={props.bgImg} width={props.bgImgWidth} height={props.bgImgHeight} alt="Background"/> */}
        </div>

        <div className="appointBannerContent flex-column flex-centered">
            <div className="appointBannerLogoDiv">
                <Image src={props.logoImg} width={props.logoImgWidth} height={props.logoImgHeight} alt="Logo"/>
            </div>
            <div className="appointBannerTitleDiv">
                <h2>{props.title}</h2>
            </div>
            <div className="appointBannerMenuDiv">
                {props.MenuList && (
                    <ul className="flex-centered">
                        {props?.MenuList?.map((item,i) =>{
                            return <div key={i} className="appointBannerMenu flex">{i > 0 ? <p> | </p> : null} <Link href={item.href} >{item.menu}</Link></div>
                        })}
                    </ul>
                )}
            </div>
            <div className="appointBannerSubtitle">
                <p>{props.subTitle}</p>
            </div>
            <div className="appointBannerBtn">
                <Button variant="btn-light" title={props.btnTitle} />
            </div>
        </div>
    </div>
  )
}

export default AppointBanner