import React, { useEffect, useState } from "react";
import Image from "../commonComponent/Image/Image";
import RootLayout from "../Layout";
import "./ProductDetail.css";

import Button from "../commonComponent/Button/Button";
import Wishlist from "../../assets/images/icon-favourite @2x.png";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { BsCaretRightFill } from "@react-icons/all-files/bs/BsCaretRightFill.esm";
import { BsCaretDownFill } from "@react-icons/all-files/bs/BsCaretDownFill.esm";

import ProductCard from "../commonComponent/Product/Product";

import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  getProduct,
  getProductDetail,
  removeFavorite,
} from "../../store/productSlice";
import productService from "../../services/axios/product";

import { FiHeart } from "@react-icons/all-files/fi/FiHeart.esm";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart.esm";
import { toCurrencyFormat } from "../CommonFunction/CurrencyFormat";
import { useTextLimit } from "../CommonFunction/TextLimit";
import BackPageCompo from "../commonComponent/BackPageCompo/BackPageCompo";
import { removeCart, addCart } from "../../store/orderSlice";

import {CgClose} from '@react-icons/all-files/cg/CgClose.esm'

// ======================Slider======================================
// import Slider from 'slider-moon';
// import 'slider-moon/dist/style.css'

// import Slider from 'react-touch-drag-slider'

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
// ======================Slider======================================




const ProductImgSection = (props) => {
  return (
    <div className="productDetailImgDiv gap-1 flex-centered">
      <div className="productDetailImgAbsDiv flex-centered">
        <img src={props.img} width="" height="" />
      </div>
    </div>
  );
};

const DropdownBox = (props) => {
  const [dropdownBoxToggle, setDropdownBoxToggle] = useState(props.status);

  const handleDropdownBoxToggle = () => {
    setDropdownBoxToggle((prev) => !prev);
  };

  return (
    <>
      <div className="dropdownBoxSection flex-column">
        <hr />

        <button className="dropdownButton" onClick={handleDropdownBoxToggle}>
          {props.title}
          {!dropdownBoxToggle ? <BsCaretRightFill /> : <BsCaretDownFill />}
        </button>

        <div className="dropdownBoxText flex">
          <p
            style={{
              height: dropdownBoxToggle ? "fit-content" : "0em",
            }}
          >
            {useTextLimit(props?.content, 200)}
          </p>
        </div>
      </div>
    </>
  );
};
DropdownBox.defaultProps = {
  status: true,
};

function ProductDetail() {
  const [product, setProduct] = useState(null);
  const [productList, setProductList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const { favoritesList } = useSelector((store) => store.product);
  const { cartList } = useSelector((store) => store.order);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    productService
      .details({ id })
      .then((res) => {
        setProduct(res?.data?.response?.details);

        if(res?.data?.response?.details?.category)
        dispatch(getProduct({category:res?.data?.response?.details?.category,pageSize:4})).then((resp) => {
          console.log("Product List", resp);
          setProductList(resp?.response?.list);
        });

      })
      .catch((error) => {
        console.log("error on Product Details slice", error.request);
      });


      const element = document.getElementById("product");
      element.scrollIntoView()

  }, [id]);

  const handleToggle = () => {
      setToggle((prev) => !prev)
  }



  return (<>

{/* =====================================Popup Window================================================= */}
<div className='popupSection' 
    style={{
        display: toggle? 'block' : 'none'
    }}
>
    <div className='popupBackground'></div>
    <div className='popupCloseBtn'>
        <button className='closeBtn' onClick={handleToggle}>
            <CgClose style={{
                fill: '#fff', 
                fontSize: '1.5em',
                cursor: 'pointer',
                color: 'white'
            }}/>
        </button> 
    </div>
    <Slider>
        {product?.images?.map((img, index) => <div className='flex-centered'>
            <div className='popupImgSection flex-centered'>
                <div className='popupImgAbs flex-centered'>
                    <img src={img?.path} key={index} />
                </div>
            </div>
            
        </div> 
        )}
    </Slider>
</div>
                

{/* =====================================Popup Window================================================= */}

    <RootLayout>
      <hr
        style={{
          marginTop: "0.5em",
          marginBottom: "4em",
        }}
      />

      <div className="productDetailSection w-100 flex-centered flex-column gap-2" id='product'>
        <div className="w-80">
          <BackPageCompo href="/categories/pret" />

          <h3 className="productDetailTxtH3">
            CATEGORIES / PRET 
          </h3>
        </div>
        <div className="productDetailDiv flex gap-2">
          <div className="productDetailImgContainer flex-column gap-1">
            <div className=" gap-2">
              {product?.images.slice(0,4).map((img, i) => {
                return (
                  <>
                    <ProductImgSection key={i} img={img?.path} />
                  </>
                );
              })}
            </div>
            <div className='w-100' 
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <button 
                        onClick={handleToggle}
                        style={{
                            width: 'fit-content',
                            border: 'none',
                            fontSize: '',
                            textDecoration: 'underline',
                            background: 'none',
                            cursor: 'pointer',
                        }}>View As Slider</button>
                    </div>
          </div>
          

          <div className="productTextContentSection flex-column gap-1">
            <h4>{(product?.is_new_arrival) ? "New Arrival | " : null} {product?.category}</h4>

            <div className="productTextContentWishlistIcon">
              {/* <div className="productWishlistIconBorder flex-centered">
                {favoritesList.includes(id) ? (
                  <FaHeart
                    onClick={() => dispatch(removeFavorite(id))}
                    style={{
                      fill: "red",
                    }}
                  />
                ) : (
                  <FiHeart
                    onClick={() => dispatch(addFavorite(id))}
                    style={{
                      width: "100%",
                      // height: '100',
                    }}
                  />
                )}
              </div> */}
            </div>

            <p className="productTextDesc">{product?.product_name}</p>

            <div className="productTextPriceDiv">
              <h2>{toCurrencyFormat(product?.sale_price)}</h2>
            </div>
{/* 
            <div className="flex w-100">
              <hr />
            </div>

            <div className="productSizeDiv flex-column">
              <p>SELECT SIZE</p>

              <div className="productSize flex gap-2">
                {product?.availability?.map((avl, i) => <NavLink to="">{avl.size}</NavLink>)}
              </div>

              <p className="">
                Size Guide
                <BsCaretRightFill
                  style={{
                    marginLeft: "0.5em",
                    fontSize: "0.8em",
                  }}
                />
              </p>
            </div> */}

            {/* {cartList?.findIndex((itm) => itm.id == id) !== -1 ? (
              <Button
                title="Remove from cart"
                variant="btn-darkFill"
                onClick={() => dispatch(removeCart(id))}
              />
            ) : (
              <Button
                title="Add to Bag"
                variant="btn-darkFill"
                onClick={() => dispatch(addCart({ id, size: "X" }))}
              />
            )} */}

            <div className="flex-column gap-2 w-100">
              <DropdownBox title="DESCRIPTION" content={product?.description} />

              {/* <DropdownBox
                title="DETAILS"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip ex
                            ea commodo consequat."
              /> */}

              <DropdownBox
                // status={false}
                title="SHIPPING AND RETURN"
                content={product?.shipping_returns}
              />
            </div>
          </div>
        </div>

        <div className="ymalSection w-100 flex-centered flex-column">
          <h2>You May Also Like</h2>
          <div className="ymalContent flex-centered gap-2">

          {productList?.map((product, ind) => <ProductCard details={product} key={product?.id} />)}
            
          </div>
        </div>
      </div>
    </RootLayout>

    </>
  );
}

export default ProductDetail;
