import './globals.css'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import ShortBanner from './components/ShortBanner/ShortBanner'

export const metadata = {
  title: 'Create Next App',
  description: 'Generated by create next app',
}

export default function RootLayout(props) {
  return (
    <html lang="en">

      <Navbar />

      <body   
        className={props.className}
      >
          {props.children}
      </body>

      <ShortBanner 
        title="SIGN UP & SAVE 10% ON YOUR PURCHASE"
        input={true}
        hint="Enter your email here"
        name="email"
        btnTitle=">"
      />
      <Footer />
    </html>
  )
}
