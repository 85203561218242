import { createSlice } from "@reduxjs/toolkit";

import productService from '../services/axios/product/index'



  // export const getCartList = (param) => async (dispatch) => {
  //   // dispatch(loadingStatus(true));
    
  //       return productService
  //           .cartList(param)
  //           .then(async (res) => {
  //               console.log("resp at cart slice", res.data);
  //               return res.data;
  //           })
  //           .catch((error) => {
  //               console.log("error on Cart slice", error);
  //               return error;
  //           });
  //   };

  export const getFavoriteParoducts = (param) => async (dispatch) => {
    return productService
      .favoriteList()
      .then(async (res) => {
        return res?.data;
      })
      .catch((error) => {
        return error;
      });
  };
  export const getProduct = (param) => async (dispatch) => {
  
    return productService
      .list(param)
      .then(async (res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  };

  export const addFavorite = (id) => async (dispatch) => {
    dispatch(addFavoriteAction(id))
  };

  export const removeFavorite = (id) => async (dispatch) => {
    dispatch(removeFavoriteAction(id))
    };


const initialState = {
    list: [],
    favoritesList:[]
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        
        // addFavoriteAction:(state,action)=>{
        //     state.favoritesList=[...state.favoritesList, action.payload]
        // },
        // removeFavoriteAction:(state,action)=>{
        //     state.favoritesList=state.favoritesList.filter((itm)=>itm!=action.payload)
        // },
        // addCart:(state,action)=>{
        //   console.log("Add Cart",action.payload)
        //   if(state.cartList.findIndex((itm)=>itm.id==action.payload.id)==-1){
        //   state.cartList=[...state.cartList, action.payload]
        //   }
        // },
        // removeCart:(state,action)=>{
        //     state.cartList=state.cartList.filter((itm)=>itm.id!=action.payload.id)
        // },
        addFavoriteAction:(state,action)=>{
          state.favoritesList=[...state.favoritesList, action.payload]
      },
      removeFavoriteAction:(state,action)=>{
          state.favoritesList=state.favoritesList.filter((itm)=>itm!=action.payload)
      },
        setList: (state, action) => {
        state.list = action.payload;
      },
    },
    extraReducers: {},
  });


export const { setList, addFavoriteAction, removeFavoriteAction } = productSlice.actions;

export default productSlice.reducer;