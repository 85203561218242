import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../commonComponent/Button/Button';
import Input from '../../commonComponent/Input/Input'
import './Login.css'

import bg1 from '../../../assets/images/hero-slider-bg1@2x.png'
import logo from '../../../assets/images/logo-bug-footer@2x.png'

import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'
import { useDispatch } from 'react-redux';
import { getAuth, getLogin } from '../../../store/authSlice';

function Login() {

    const [payLoad, setPayload] = useState({});

    const dispatch = useDispatch();

    const [authDetails, setAuthDetails] = useState({});
    let [searchParams, setSearchParams] = useSearchParams();
    console.log('back url is ',searchParams.get('returnTo'))
    const navigate = useNavigate();

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        console.log(name,value);
    }

    async function handleAuth(){
        dispatch(getLogin(payLoad,navigate))
            .then((resp) => {
                if(resp?.status === 200){
                    // navigate('/profile');
                }else{
                    alert(resp?.response?.data?.message)
                }
            })
            .catch((err) => {
                console.log("error : ",err)

            })
    }

  return (
    <div className='authenticationSection flex-centered w-100'
        style={{
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}
    >
        <div className='w-30 flex-column flex-centered gap-1'>
            <div className='authBackground'>
                <NavLink to='/'><BiArrowBack /></NavLink>
            </div>

            <div className=''>
                
            </div>

            <div className='authHeading'>
                <h2>Login Here</h2>
            </div>
            <div className='authInputDiv flex-column w-100 gap-1'>
                <Input 
                    name="email"
                    label='Email'
                    handleChange={(name,value) => 
                        {handleChange(name,value)}}
                />

                <Input 
                    name="password"
                    label='Password'
                    type="password"
                    handleChange={(name,value) => 
                        {handleChange(name,value)}}
                />

                <NavLink className='forgotText' to='/reset-pass'>forgot password?</NavLink>

                <div className='authBtndiv flex'>
                    <Button 
                        title="Login"
                        variant="btn-darkFill"
                        onClick={handleAuth}
                    />
                </div>
                <br/>
                <br/>
                <NavLink className='authChangeLink' to='/sign-up'>Did'nt have an account? Register here</NavLink>

            </div>
            
        </div>
    </div>
  )
}

export default Login