import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class productService {

    addNew = async (payload) => {
        return await postRequest('product', payload);
    }

    update = async (payload,id=null) => {
        return await putRequest('product', payload);
    }

    details = async ({id}) => {
        return await getRequest(`product/details?id=${id}`);
    }

    // list = async (urlParam = {}) => {
    //     return await getRequest('product/list', urlParam);
    // }

    favoriteList = async (urlParam={}, payload) => {
        return await getRequest(`product/list`, urlParam);
    }

    cartList = async ({id}) => {
        return await getRequest(`cart/list?product_id=${id}`);
    }

    list = async (urlParam = {}) => {
        console.log('param here are ',urlParam)
        return await getRequest('product/list', urlParam);
    }

    delete = async (urlParam = {}, payload) => {
        return await deleteRequest('product', urlParam);
    }

}

const instance = new productService();

export default instance;