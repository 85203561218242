import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class authService {

    getLogin = async (urlParam = {}, payload) => {
        return await postRequest('customer/login', urlParam);
    }

    getLogout = async (urlParam = {}, payload) => {
        return await postRequest('customer/logout', urlParam);
    }
}

const instance = new authService();

export default instance;