import { configureStore,combineReducers } from "@reduxjs/toolkit"
import authSlice from "./authSlice"
// import cartSlice from "./cartSlice"
import orderSlice from "./orderSlice"
import productSlice from "./productSlice"
import { persistReducer,persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import customerSlice from "./customerSlice"
import staticSlice from "./staticSlice"



const persistConfig = {
    key: 'root',
    storage,
  }
  
  
  const rootReducer = combineReducers({ 
    product: productSlice,
        customer: customerSlice,
        auth: authSlice,
        order: orderSlice,
        static:staticSlice
  })
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
  export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production'
  })
  
  export const persistor = persistStore(store)