import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from './../commonComponent/Button/Button';
import RootLayout from './../Layout';
import './CartFailed.css'

import {RxCross1} from "react-icons/rx";


function CartFailed() {

    const navigate = useNavigate();

  return (
    <RootLayout>
        <div className='failedSection w-100 flex-centered'>
            <div className='flex-column gap-1 w-40 flex-centered'>
                <div className='failedIconDiv flex-centered'>
                    <RxCross1 style={{
                        fill: 'white',
                        fontSize: '2em',
                        color: 'white',
                    }}/>
                </div>
                <p>Failed</p>
                <h3>Oops! It seems there was a tiny hiccup - payment declined.</h3>
                {/* <h3>welcome to an amazing experience!</h3> */}
                {/* <p>REFERENCE NUMBER: <b>#455676543</b></p> */}

                <div className='failedBorder'></div>

                <Button
                    title="Continue Shopping"
                    variant="btn-dark"
                    onClick={()=>{navigate('/')}}
                />
            </div>
            
        </div>
    </RootLayout>
  )
}

export default CartFailed