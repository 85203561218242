import React, { useEffect, useState } from 'react'
import Image from '../../commonComponent/Image/Image'
import './MyOrderDetail.css'

import ProductImg1 from '../../../assets/images/product/product001.jpg'
import ProductImg2 from '../../../assets/images/product/product002.jpg'
import ProductImg3 from '../../../assets/images/product/product003.jpg'
import ProductImg4 from '../../../assets/images/product/product004.jpg'
import RootLayout from '../../Layout'
import Button from '../../commonComponent/Button/Button'
import { useDispatch } from 'react-redux'
import { getDetails } from '../../../store/orderSlice'
import orderService from '../../../services/axios/order'
import { useParams } from 'react-router-dom'
import { useTextLimit } from '../../CommonFunction/TextLimit'
import { toCurrencyFormat } from '../../CommonFunction/CurrencyFormat'
import BackPageCompo from '../../commonComponent/BackPageCompo/BackPageCompo'


const OrderDetailCard = (props) => {

    return (
        <>
        <hr/>
        <br/>
        <div className="orderDetailCardSection flex-between w-100 gap-2">
            <div className='orderDetailCardImg w-100 flex gap-1'>
                <div className='orderDetailCardImgAbs'>
                    <img src={props.img} />
                </div>
                <h3>{useTextLimit(props.productName, 15)}</h3>
            </div>
            <div className='quantity w-50'>
                <p>{props.quantity}</p>
            </div>
            {
                window.innerWidth >= 768 ? <>
                        <div className='price w-50'>
                            <p>{toCurrencyFormat(props.price)}</p>
                        </div>
                        <div className='totalPrice w-50'
                            style={{
                                // textAlign: 'right'
                            }}
                        >
                            <p >{toCurrencyFormat(props.totalPrice)}</p>
                        </div>
                    </>
                : ''
            }
        </div>

        </>
    )
}


const CustomerDetailCard = (props) => {

    return (
        <>
        <hr/>
        <div className="customerDetailCardSection flex-between w-100 gap-2">
            <div className='w-100 flex gap-1'>
                <h3>{props.name}</h3>
            </div>
            <div className='w-100'
                style={{
                    // textAlign: 'right',
                }}
            >
                <p>{props.value}</p>
            </div>
        </div>

        </>
    )
}

const OrderSummaryCard = (props) => {

    return(<>
        <div className='orderSummaryCardSection flex-between'>
            <div className=''>
                <h3>{props.name}</h3>
            </div>
            <div className=''>
                <p>{props.value}</p>
            </div>
        </div>
    </>)
}


const DeliveryAddressCard = (props) => {

    return(<>
        <div className='deliveryAddressCardSection flex gap-1'>
            <div className=''>
                <h3>{props.name}</h3>
            </div>
            {
                window.innerWidth <= 380 ? ''
                :
                    <div className=''>
                        <p>:</p>
                    </div>
            }
            <div className=''>
                <p>{props.value}</p>
            </div>
        </div>
    </>)
}

function MyOrderDetail() {

    const [orderDetail, setOrderDetail] = useState({});
    const {id} = useParams();

    useEffect(() => {
        orderService.details({id})
            .then((resp) => {
                setOrderDetail(resp?.data?.response)
                console.log("Order Detail : ",resp?.data?.response)
            })
            .catch((err) => {
                console.log("Error Detail : ",err?.response?.data?.message)
                // alert(err?.response?.data?.message)
            })
    },[])

  return (
    <RootLayout>
        <div className='myOrderDetailSection flex-centered flex-column gap-2 w-100'>
            <BackPageCompo 
                href="/my-profile"
            />
            <div className='flex gap-2'>
                <div className='orderDetailImgContainer flex-column gap-2 w-100'>
                    <div className='flex-column gap-2 w-100'>
                        <div className='flex-column gap-1 w-100'>
                            <div className='orderDetailSummary flex-between gap-2 w-100'>
                                <div className='orderDetailSummaryImg w-100'>
                                    <h2>Items Summary</h2>
                                </div>
                                <div className='w-50'>
                                    <h2>qty</h2>
                                </div>
                                {
                                    window.innerWidth >= 768 ? <>
                                        <div className='w-50'>
                                            <h2>Price</h2>
                                        </div>
                                        <div className='w-50'
                                            style={{
                                                // textAlign: 'right'
                                            }}
                                        >
                                            <h2>Total Price</h2>
                                        </div>
                                    </>
                                    :''
                                    }
                            </div>
                            {orderDetail?.product_detail?.map((product,i) => {
                                return <OrderDetailCard 
                                    img={product?.primary_image}
                                    productName={product?.product_name}
                                    quantity="2"
                                    price={product?.sale_price}
                                    totalPrice={product?.mrp}
                                />
                            })}
                            
                        </div>
                        
                        <div className='orderDetailSummarySection flex-column gap-1'>
                            <div className='orderDetailSummary flex-between gap-2 w-100'>
                                <div className='w-100'>
                                    <h2>Customer And Order Details</h2>
                                </div>
                                <div className='w-50'>
                                    <h2></h2>
                                </div>
                                
                            </div>
                            <div className='flex-column'>
                                <CustomerDetailCard 
                                    name='Customer Name'
                                    value={orderDetail?.customer_detail?.name}
                                />
                                <CustomerDetailCard 
                                    name='Phone Number'
                                    value={orderDetail?.customer_detail?.mobile}
                                />
                                <CustomerDetailCard 
                                    name='Email'
                                    value={orderDetail?.customer_detail?.email}
                                />
                                <CustomerDetailCard 
                                    name='Mode of Payment'
                                    value='UPI - lucky@okhedfc.ybl'
                                />
                                <CustomerDetailCard 
                                    name='Status'
                                    value='Delivered'
                                />
                            </div>
                            
                        </div>
                        
                        <div className='orderDetailGrandTotalSection flex-column gap-1'>
                            <div className='orderDetailGrandTotal flex-between gap-2 w-100'>
                                <div className=''>
                                    <h2>Total</h2>
                                </div>
                                <div className=''>
                                    <h2>{toCurrencyFormat(orderDetail?.grand_total)}</h2>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>





                <div className='orderDetailTxtDiv flex-column gap-2'>
                    <div className='orderDetailTrackOrder flex-column gap-1'>
                        <h2>Track Order</h2>

                        <div className='flex-between flex-centered'>
                            <p>Delivered on 20 july</p>

                            <Button 
                                title="Track Order"
                                variant="btn-darkFill"
                            />
                        </div>
                    </div>

                    <div className='orderedSummaryDetail flex-column gap-2'>
                        <div className='orderedSummaryDetailDiv flex-between flex-centered gap-2 w-100'>
                            <div className='w-100'>
                                <h2>Order Summary</h2>
                            </div>
                            <div className='w-50'>
                                <div className='orderSummaryStatus'>
                                    <p>Delivered</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className='flex-column gap-1'>
                            <OrderSummaryCard 
                                name="Order Created"
                                value="Thus, july 20, 2023"
                            />
                            <OrderSummaryCard 
                                name="Order Time"
                                value="06:24 AM"
                            />
                            <OrderSummaryCard 
                                name="Subtotal"
                                value={toCurrencyFormat(orderDetail?.sub_total)}
                            />
                            <OrderSummaryCard 
                                name="Tax"
                                value={`+ ${toCurrencyFormat(orderDetail?.tax_total)}`}
                            />
                            <OrderSummaryCard 
                                name="Delivery Fee"
                                value="&#8377; 0.00"
                            />
                        </div>
                        
                    </div>

                    <div className='deliveryAddressSection flex-column gap-2'>
                        <div className='deliveryAddressDiv flex-between flex-centered gap-2 w-100'>
                            <div className='w-100'>
                                <h2>Delivery Address</h2>
                            </div>
                        </div>
                        <div className='flex-column gap-1'>
                            <DeliveryAddressCard 
                                name="Address line"
                                value={orderDetail?.customer_detail?.address1}
                            />
                            <DeliveryAddressCard 
                                name="Flat / Building"
                                value={orderDetail?.customer_detail?.address2}
                            />
                            <DeliveryAddressCard 
                                name="City, State"
                                value={`${orderDetail?.customer_detail?.city},  ${orderDetail?.customer_detail?.state}`}
                            />
                            <DeliveryAddressCard 
                                name="Postcode"
                                value={`${orderDetail?.customer_detail?.zipcode}, ${orderDetail?.customer_detail?.country}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </RootLayout>
  )
}

export default MyOrderDetail