import Button from '../../commonComponent/Button/Button';
import './Banner.css'

import { useEffect, useState } from 'react';
import Image from '../../commonComponent/Image/Image';

function Banner(props) {

    const [width, setWidth] = useState(0)

    useEffect(()=>{
        setWidth(window.innerWidth);
    }, [])
    
    const divResponsive = {

    }

    const bannerContainer = {
        justifyContent: props.bannerContainerAlign,
    }
    
    const imageDiv = {
        filter: props.blur,
    }
    return (
        <div className="bannerSection">
            <div style={bannerContainer} className="bannerContainer">
                <div style={imageDiv} className="bannerImgDiv">
                    <img 
                        src={props.img}  
                        width="100%" 
                        height="100%" 
                        alt="Image"/>
                </div>
                {/* <div style={{}} className="bannerText flex-column ">
                    <h3>An <i>Iternal</i> Romance</h3>
                    
                    <p>{props.subHeading}</p>
                    
                    <Button variant="btn-light" title={props.btnTitle}/>
                </div> */}
            </div>
        </div>
    )
}

export default Banner