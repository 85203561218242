import React, { useEffect, useRef, useState } from "react";
import "./Cart.css";
import Button from "../commonComponent/Button/Button";
import RootLayout from "../Layout";
import Image from "../commonComponent/Image/Image";

import CartCardImg1 from "../../assets/images/product/product001.jpg";
import CartCardImg2 from "../../assets/images/product/product002.jpg";
import CartCardImg3 from "../../assets/images/product/product003.jpg";
import CartCardImg4 from "../../assets/images/product/product004.jpg";
import Dropdown from "../commonComponent/Dropdown/Dropdown";

import { BsTrashFill } from "@react-icons/all-files/bs/BsTrashFill.esm";
import { NavLink } from "react-router-dom";
import { FiHeart } from "@react-icons/all-files/fi/FiHeart.esm";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart.esm";
import { useDispatch, useSelector } from "react-redux";
import { getCartList } from "../../store/cartSlice";
import ProductSlice from "../../store/productSlice";
import productService from "../../services/axios/product";
import BackPageCompo from "../commonComponent/BackPageCompo/BackPageCompo";
import orderService from "../../services/axios/order";
import { toCurrencyFormat } from "../CommonFunction/CurrencyFormat";
import { removeCart } from "../../store/orderSlice";

const AddOrRemoveQuantity = ({ onValueChange }) => {
  const [count, setCount] = useState(1);
  const pRef = useRef(null);

  const handleClickAdd = (e) => {
    e.preventDefault();

    if (count >= 10) {
      setCount(10);
    } else {
      setCount(count + 1);
    }
  };

  const handleClickSubs = (e) => {
    e.preventDefault();
    if (count <= 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    if (pRef.current) {
      // onValueChange(pRef.current.innerText);
      // console.log(onValueChange(pRef.current.innerText));
    }
  }, [handleClickAdd, handleClickSubs]);

  return (
    <>
      <div className="addOrRemoveQuantity flex-centered gap-1">
        <button className="" onClick={handleClickSubs}>
          -
        </button>
        <p ref={pRef}>{count}</p>
        <button className="" onClick={handleClickAdd}>
          +
        </button>
      </div>
    </>
  );
};

const CartProduct = ({ details }) => {
  const dispatch = useDispatch();
  console.log("cart prd details", details);

  // const handleChange = (name,value) => {
  //         setPayload({
  //             ...payload,
  //             [name]: value
  //         })
  //         console.log(name, value);
  // }

  // const options = [
  //   {
  //     value: "1",
  //     name: "XS",
  //   },
  //   {
  //     value: "2",
  //     name: "S",
  //   },
  //   {
  //     value: "3",
  //     name: "M",
  //   },
  //   {
  //     value: "4",
  //     name: "L",
  //   },
  //   {
  //     value: "5",
  //     name: "XL",
  //   },
  //   {
  //     value: "6",
  //     name: "XXL",
  //   },
  // ];

  return (
    <div className="">
      <hr />
      <div className="cartProduct flex gap-1">
        <div className="cartProductImg">
          <Image src={details.primary_image} width="100%" />
        </div>
        <div className="cartProductTxt w-100 flex-column gap-2">
          <div className="flex-between flex-centered">
            <div className="cartProductName flex-column gap-1">
              <h3>{details.product_name}</h3>
              {window.innerWidth > 600 ? (
                <div className="cartProductPrice flex">
                  {/* <h2>&#8377; {product.total}</h2> */}
                  <p>&#8377; {details.sale_price}</p>
                  <p>|</p>
                  <p className="cartProductAvailability">In Stock</p>
                </div>
              ) : (
                ""
              )}
            </div>
            {window.innerWidth < 600 ? (
              <div className="cartProductPrice flex-centered cartProductTotalPrice cartProductTotalPriceMob">
                <div className="cartProductFavIcon">
                  <NavLink to="/">
                    <FiHeart />
                  </NavLink>
                </div>
                <h2>&#8377; {details.total}</h2>
                {/* <p>&#8377; {product.price}</p> */}
                <p>|</p>
                <p className="cartProductAvailability">In Stock</p>
              </div>
            ) : (
              <div className="cartProductTotalPrice">
                <h2>&#8377; {details.total_price}</h2>
              </div>
            )}
          </div>

          <div className="cartProductBottomDiv flex-between">
            {/* <div className='cartProductQuantityDiv flex gap-2'>
                    <Dropdown 
                        name="productSize"
                        options={options}
                        handleChange={(name,value)=>handleChange(name, value)}
                    />
                    <div className='cartProductSizeDiv'>
                        <AddOrRemoveQuantity onValueChange={handleProductCount} />
                        
                    </div>
                    
                </div> */}

            <div className="cartProductSaveOrDelDiv flex-centered gap-1">
              {window.innerWidth < 600 ? (
                <div className="cartProductDelete">
                  <NavLink to="/">
                    <BsTrashFill
                      style={{
                        fill: "grey",
                      }}
                    />
                  </NavLink>
                </div>
              ) : (
                <>
                  <div className="">
                    <NavLink to="/">
                      <FiHeart />
                    </NavLink>
                  </div>
                  <p>|</p>
                  <div className="">
                    <NavLink onClick={()=>dispatch(removeCart(details?.id))}>
                      <BsTrashFill
                        style={
                          {
                            // fill: 'grey',
                          }
                        }
                      />
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Cart = (props) => {
  const pform = useRef(null);
  const [ppDetails, setPpDetails] = useState({});
  const { cartList } = useSelector((store) => store.order);
  const [cartDetail,setCartDetailList]=useState([]) 

console.log()  

useEffect(()=>{
  if(cartList.length>0){
    orderService
    .cartDetails({product:cartList})
    .then((resp)=>{
        setCartDetailList(resp?.data?.response)
    })
  }
    
},[cartList])


  console.log("carttttttttttt", cartList);
  const handleCheckout = () => {
    orderService
      .create({
        products: cartList,
      })
      .then((resp) => {
        console.log("response at src", resp?.data?.response);
        setPpDetails(resp?.data?.response);

        setTimeout(() => {
          pform.current.submit();
        }, 2000);
      })
      .catch((err) => {
        console.log("order error", err);
        alert("Something went wrong");
      });
  };

  return (
    <RootLayout>
      {
        cartList.length==0?<h1 style={{color:'red'}}> You don't have any product in your cart.</h1>:
        <>
      <form ref={pform} method="POST" action={ppDetails?.paymentUrl}>
        <input value={ppDetails?.encRequest} name="encRequest" />
        <input value={ppDetails?.access_code} name="access_code" />
      </form>
      <div className="cartSection flex-centered flex-column w-100 gap-2">
        <BackPageCompo href="/" />
        <div className="w-80 flex gap-2">
          <div className="cartProductList w-100">
            <h2>Cart</h2>
            {/* <br/> */}
            <div className="flex-column">
              {cartDetail?.product?.map((itm) => {
                return <CartProduct details={itm} />;
              })}

            </div>
          </div>
          <div className="cartProductTotal flex-column gap-1">
            <div className="flex-between ">
              <h3>Subtotal</h3>
              <h3>{toCurrencyFormat(cartDetail?.subTotal)}</h3>
            </div>
            {/* <div className="flex-between ">
              <p>Discount</p>
              <p>(20%) - &#8377; 16000.00</p>
            </div> */}
            {/* <div className="flex-between ">
              <p>Delivery</p>
              <p>&#8377; 0.00</p>
            </div> */}
            <div className="flex-between ">
              <p>Tax</p>
              <p>{toCurrencyFormat(cartDetail?.taxTotal)}</p>
            </div>

            <hr />

            <div className="flex-between ">
              <h2>Total</h2>
              <h2>{toCurrencyFormat(cartDetail?.grandTotal)}</h2>
            </div>

            <Button
              onClick={handleCheckout}
              title="Proceed to checkout"
              variant="btn-darkFill"
            />
            <Button title="Continue Shopping" variant="btn-sec" />
          </div>
        </div>
      </div>
      </>
      }
    </RootLayout>
  );
};

export default Cart;
