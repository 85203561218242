import { createSlice } from "@reduxjs/toolkit";

const staticSlice = createSlice({
  name: "static",
  initialState:{mobile:''},
  reducers: {},
  extraReducers: {},
});

export const { } = staticSlice.actions;

export default staticSlice.reducer;
