import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import productService from '../../services/axios/product';
import { getFavoriteParoducts } from '../../store/productSlice';
import Button from '../commonComponent/Button/Button';
import ProductCard from '../commonComponent/Product/Product';
import RootLayout from '../Layout'
import './Favorite.css'

function Favorite() {

    const { favoritesList } = useSelector(store => store.product);
    const dispatch = useDispatch();

    const [favoriteProductList, setFavoriteProductList] = useState([]);

    useEffect(() => {
        
        productService.favoriteList()
        .then((resp) => {
            console.log(resp?.data?.response?.list)
            // setFavoriteProductList(resp?.data?.response?.list?.filter((item) => {favoritesList?.includes(item?.product_id)}))
            setFavoriteProductList(resp?.data?.response?.list)

        })
        .catch((err) => {
            console.log(err)
        })
    }, [])

    // console.log("favoriteLsit : ", favoritesList)
    // console.log("favorite : ", favoriteProductList)
    
  return (
    <RootLayout>
        <div className="pretProductSection w-100 flex-centered flex-column gap-1">
                <div className="pretProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <div className="pretProductHeading">
                        <p>CATEGORIES / Favorites /</p>
                        <h2>Shop All</h2>
                    </div>
                    <div className="pretProductHeaderBottom flex flex-between">
                        <div className="pretProductFilter">
                            <div className='pretProductFilterTxt flex'>
                                <p>FILTERS </p>
                                {/* <RiArrowDropDownFill style={{ fontSize: '1.5em' }} /> */}
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Filter 1</NavLink></li>
                                <li><NavLink>Filter 2</NavLink></li>
                                <li><NavLink>Filter 3</NavLink></li>
                                <li><NavLink>Filter 4</NavLink></li>
                            </ul>
                        </div>
                        <div className="pretProductSortBy">
                            <div className='pretProductSortByTxt flex'>
                                <p>SORT BY </p>
                                {/* <RiArrowDropDownFill style={{ fontSize: '1.5em' }} /> */}
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Sort By 1</NavLink></li>
                                <li><NavLink>Sort By 2</NavLink></li>
                                <li><NavLink>Sort By 3</NavLink></li>
                                <li><NavLink>Sort By 4</NavLink></li>
                            </ul>
                            <div className="pretProductGrid">

                            </div>
                        </div>

                    </div>
                </div>
                {/* <br/> */}
                {/* <br/> */}
                <div className="pretProductContent flex-centered flex-column gap-3">

                    <div className='flex-centered w-100'>

                        {favoriteProductList?.map((product, i) => {
                            return (<>
                                <ProductCard
                                    key={i}
                                    id={product?.product_id}
                                    img={product?.primary_image}
                                    title={product?.description}
                                    price={product?.mrp}
                                />
                            </>)
                        })}
                    </div>


                    <div className='flex-centered productBtnDiv '>
                        <Button
                            title="+ VIEW 32 MORE"
                            variant="btn-dark"
                        />
                    </div>
                </div>
            </div>
    </RootLayout>
  )
}

export default Favorite