import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import Button from '../../commonComponent/Button/Button';
import Input from '../../commonComponent/Input/Input'
import './ForgotPass.css'

import bg1 from '../../../assets/images/hero-slider-bg1@2x.png'
import logo from '../../../assets/images/logo-bug-footer@2x.png'

import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'


function ForgotPass() {

    const [payLoad, setPayload] = useState({});

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        console.log(name,value);
    }

  return (
    <div className='authenticationSection flex-centered w-100'
        style={{
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}
    >
        <div className='w-30 flex-column flex-centered gap-1'>
            <div className='authBackground'>
                <NavLink to='/login'><BiArrowBack /></NavLink>
                
            </div>

            <div className=''>
                
            </div>

            <div className='authHeading'>
                <h2>Reset Password</h2>
            </div>
            <div className='authInputDiv flex-column w-100 gap-1'>
                <Input 
                    name="email"
                    label='Email'
                    handleChange={(name,value) => 
                        {handleChange(name,value)}}
                />

                {/* <NavLink className='forgotText' to='/reset-pass'>forgot password?</NavLink> */}

                <div className='authBtndiv flex'>
                    <Button 
                        title="Next"
                        variant="btn-darkFill"
                    />
                </div>
                <br/>
                <br/>
                {/* <NavLink className='authChangeLink' to='/sign-up'>Did'nt have an account? Register here</NavLink> */}

            </div>
            
        </div>
    </div>
  )
}

export default ForgotPass