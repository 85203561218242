import React from 'react'
import RootLayout from './../Layout'
import './CartSuccess.css'

import {AiOutlineCheck} from '@react-icons/all-files/ai/AiOutlineCheck.esm'
import Button from '../commonComponent/Button/Button'
import { useNavigate } from 'react-router-dom'

function CartSuccess() {

    const navigate = useNavigate();

  return (
    <RootLayout>
        <div className='successSection w-100 flex-centered'>
            <div className='flex-column gap-1 w-40 flex-centered'>
                <div className='successIconDiv flex-centered'>
                    <AiOutlineCheck style={{
                        fill: 'white',
                        fontSize: '2em',
                    }}/>
                </div>
                <p>Success</p>
                <h3>Congratulations! Your payment has been successfully processed</h3>
                {/* <h3>welcome to an amazing experience!</h3> */}
                <p>REFERENCE NUMBER: <b>#455676543</b></p>

                <div className='successBorder'></div>

                <Button 
                    title="Continue Shopping"
                    variant="btn-dark"
                    onClick={()=>{navigate('/')}}
                />
            </div>
            
        </div>
    </RootLayout>
  )
}

export default CartSuccess