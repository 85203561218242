import Card from "../../commonComponent/Card/Card.js"
import { useEffect, useState } from "react"
import './FollowUs.css'
import Image from "../../commonComponent/Image/Image.js";
import {FaInstagram} from "@react-icons/all-files/fa/FaInstagram.esm"

function FollowUs() {

    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImgHeight] = useState(0);

    useEffect(()=>{
        setImgWidth(window.innerWidth > 1200 ? "100%" :
        window.innerWidth > 768 ? '100%' : 
        
        "100%")
        setImgHeight(window.innerWidth > 1200 ? "100%" :'100%')
    }, [imgWidth,imgHeight])

  return (
    <div className="followUsSection w-100 flex-centered flex-column">
        
        <div className="flex-centered gap-1">
            <h2 style={{color: 'var(--sec-color)', 
                        textAlign: 'center', 
                        fontWeight: '400',}} className="p-txt flex-centered">FOLLOW AND TAG US #AKRITIBYSHAKUN @AKRITIBYSHAKUN ON  
            {window.innerWidth <= 530 ? " INSTAGRAM" : 
                <FaInstagram style={{marginLeft: '0.5em'}}/>
            }
            </h2>
        </div>

        <div className="followUsCardDiv w-80 flex gap-2">
            <Card 
                
                src="/images/carousel-instagram-1@2x.png"
                imgWidth={imgWidth}
                imgHeight={imgHeight}
            />

            <Card 

                src="/images/carousel-instagram-2@2x.png"
                imgWidth={imgWidth}
                imgHeight={imgHeight}
            />

            <Card 

                src="/images/carousel-instagram-3@2x.png"
                imgWidth={imgWidth}
                imgHeight={imgHeight}
            />

            <Card 

                src="/images/carousel-instagram-4@2x.png"
                imgWidth={imgWidth}
                imgHeight={imgHeight}
            />
        </div>
    </div>
    
  )
}

export default FollowUs