import { deleteRequest, getRequest, postRequest, putRequest } from '..';

/**
 * Api call
 */
class orderService {

    details = async ({id}) => {
        return await getRequest(`order?order_id=${id}`);
    }

    list = async (urlParam = {}, payload) => {
        return await getRequest('order/list', urlParam);
    }

    create = async (payload) => {
        return await postRequest('customer/order', payload);
    }

    cartDetails = async (payload) => {
        return await getRequest('cart', payload);
    }

}

const instance = new orderService();

export default instance;