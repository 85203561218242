import { getRequest } from './../../axios';

/**
 * Api call
 */
class customerService {

    getDetails = async (urlParam = {}, payload) => {
        return await getRequest('customer/profile', urlParam);
    }

    list = async (urlParam = {}) => {
        return await getRequest('customer/list', urlParam);
    }

}

const instance = new customerService();

export default instance;