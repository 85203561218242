import React, { useState } from 'react'
import './ProfileDetailUpdate.css'
import RootLayout from '../../Layout'
import Input from '../../commonComponent/Input/Input'
import Button from '../../commonComponent/Button/Button'

function ProfileDetailUpdate() {

    const [payload, setPayload] = useState()

    const handleChange = (name,value) => {
        setPayload({
            ...payload,
            [name]: value,
        })
        console.log(name, value)
    }
  return (
    <RootLayout>
        <div className='profileDetailUpdateSection flex-centered flex-column gap-2 w-100'>
            
            <div className='w-80 flex-column gap-1'>
                <div className="profileDetailHeading">
                    <h2>My Details</h2>
                    <hr/>
                </div>
                <div className="profileDetailForm flex-column gap-2">
                    <div className='flex gap-1'>
                        <Input 
                            label="Name"
                            name="name"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />

                        <Input 
                            label="Mobile"
                            name="mobile"
                            type="number"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>
                    <div className='flex gap-1'>
                        <Input 
                            label="Email"
                            name="email"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Flat/House/Apartment No."
                            name="address1"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Street/Road Name"
                            name="address2"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>
                    <div className='flex gap-1'>
                        <Input 
                            label="City"
                            name="city"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="State"
                            name="state"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>
                    <div className='flex gap-1'>
                        <Input 
                            label="Country"
                            name="country"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Zip Code"
                            name="zipcode"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>

                    <div className='flex gap-1'>
                        <input 
                            type="checkbox"
                            name="sameAsCurrentAddress"
                            
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <p>if your Current Address same as Billing Addess</p>
                    </div>

                    <div className='flex gap-1'>
                        <Input 
                            label="Billing Address"
                            name="billing_address"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Billing City"
                            name="billing_city"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>
                    <div className='flex gap-1'>
                        <Input 
                            label="Billing State"
                            name="billing_state"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Billing Country"
                            name="billing_country"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                        <Input 
                            label="Billing Zip Code"
                            name="billing_zipcode"
                            handleChange={(name,value) => {
                                handleChange(name,value)}}
                        />
                    </div>
                    <br/>
                    <div className='flex-centered'>
                        <Button 
                            title="Save Info"
                            variant="btn-darkFill"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    </RootLayout>
    
  )
}

export default ProfileDetailUpdate