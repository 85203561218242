
export function toCurrencyFormat(number,currency='en-IN') {
    const formatter = new Intl.NumberFormat(currency, {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    });

    return formatter.format(number);
}