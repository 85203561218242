import Button from '../../commonComponent/Button/Button'
import './ShortBanner.css'
import {BsArrowRight} from '@react-icons/all-files/bs/BsArrowRight.esm'

function ShortBanner(props) {
  return (
    <div className='shortBannerSection flex-centered'>
        {/* <div className='shortBannerAbsDiv'>
            <Image src={props.src} width={1000} height={200}/>
        </div> */}
        <div className='shortBannerTextDiv flex-centered gap-5'>
            {props.title && (
                <p className='p-txt' style={{fontWeight: '400', fontSize: '1.5em', letterSpacing:'0.1em'}}>{props.title}</p>
            )}
            {props.input && (
                <div className='flex inputDiv'>
                    <input type='text' className='input' placeholder={props.hint} name={props.name}/>
                    {/* <Button variant="btn-light" title={props.btnTitle}/> */}
                    <BsArrowRight style={{fontSize: '3em', cursor: 'pointer'}}/>
                </div>
            )}
        </div>
    </div>
  )
}

export default ShortBanner