import React from 'react'
import './NewArrivals.css'

import RootLayout from '../Layout.js'
import Image from '../commonComponent/Image/Image'
import Product from '../commonComponent/Product/Product'

import BannerImg from '../../assets/images/product-banner01.jpg'
import Product01 from '../../assets/images/product/product001.jpg'
import Product02 from '../../assets/images/product/product002.jpg'
import Product03 from '../../assets/images/product/product003.jpg'
import Product04 from '../../assets/images/product/product004.jpg'
import Product05 from '../../assets/images/product/product005.jpg'
import Product06 from '../../assets/images/product/product006.jpg'
import Product07 from '../../assets/images/product/product001.jpg'
import Product08 from '../../assets/images/product/product002.jpg'

const ProductBanner = (props) => {

    return (
        <div className="productBannerSection flex-centered">
            <div className="productBannerImgAbs flex-centered">
                <Image className="flex-centered" src={props.img} width={props.width} height={props.height} alt="Banner"/>
            </div>
        </div>
    )
}

const ProductsContainer = (props) => {

    return(
        <div className="productsContainerSection">
            <div className="productsContainerCardDiv">
                <Product 
                    img={props.img}
                    width={330}
                    height={450}
                    title={props.title}
                    price={props.price}
                />
            </div>
        </div>
    )
}

function NewArrivals() {

  return (
    <RootLayout >
        <div className="newArrivalsSection w-100 flex-centered flex-column gap-2">
            <ProductBanner 
                img={BannerImg}
                width="80%"
                // height={600}
            />

            <div className="newArrivalProductDiv w-80 flex-column gap-2">
                <h2 className='heading-txt'>NEW ARRIVALS</h2>
                <div className='w-100 flex-centered newArrivalProduct gap-2'>
                    <ProductsContainer 
                        img={Product01}
                        title="Product Name 1"
                        price="150000"
                    />
                    <ProductsContainer 
                        img={Product02}
                        title="Product Name 2"
                        price="250000"
                    />
                    <ProductsContainer 
                        img={Product03}
                        title="Product Name 3"
                        price="100000"
                    />
                    <ProductsContainer 
                        img={Product04}
                        title="Product Name 4"
                        price="200000"
                    />
                    <ProductsContainer 
                        img={Product05}
                        title="Product Name 5"
                        price="180000"
                    />
                    <ProductsContainer 
                        img={Product06}
                        title="Product Name 6"
                        price="120000"
                    />
                    <ProductsContainer 
                        img={Product07}
                        title="Product Name 7"
                        price="230000"
                    />
                    <ProductsContainer 
                        img={Product08}
                        title="Product Name 8"
                        price="280000"
                    />
                </div>
                
            </div>
        </div>
    </RootLayout>
    
  )
}

export default NewArrivals