import { createSlice } from "@reduxjs/toolkit";
import orderService from "../services/axios/order";

// export const getDetails = (param) => async (dispatch) => {

//     return orderService
//         .details(param)
//         .then((resp) => {
//             console.log("order resp: ",resp);

//             return resp?.data;
//         })
//         .catch((err) => {
//             // console.log("customer Error: ",err);
//         })
// }

const initialState = {
  cartList: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addCart: (state, action) => {
      console.log("Add Cart", action.payload);
      state.cartList = [...state.cartList, action.payload];
    },
    removeCart: (state, action) => {
      console.log("removing", action.payload);
      state.cartList = state.cartList.filter((itm) => itm.id != action.payload);
    },

    clearCart: (state, action) => {
      state.cartList = [];
    },
  },
  extraReducers: {},
});

export const { addCart, removeCart, clearCart } = orderSlice.actions;

export default orderSlice.reducer;
