import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from './../commonComponent/Button/Button';
import RootLayout from './../Layout';
import './CartFailed.css'

import {RxCross1} from "react-icons/rx";
import { useDispatch } from 'react-redux';
import { addCart, clearCart } from '../../store/orderSlice';


function CartRespHandler() {

    const [searchParams, setSearchParams] = useSearchParams();
    const status=searchParams.get("status")
    const dispatch=useDispatch()
if(status=='Success'){
    dispatch(clearCart())
}

    const navigate = useNavigate();

  return (
    <RootLayout>
        <div className='failedSection w-100 flex-centered'>
            <div className='flex-column gap-1 w-40 flex-centered'>
                <div className='failedIconDiv flex-centered'>
                    <RxCross1 style={{
                        fill: 'white',
                        fontSize: '2em',
                        color: 'white',
                    }}/>
                </div>


            </div>
            
        </div>
    </RootLayout>
  )
}

export default CartRespHandler