import React from 'react'
import './Image.css'

function Image(props) {
  return (
    <div className='imageSection flex' style={{
      width: props.width,
      height: props.height
    }}>
        <img className={props.className} style={props.style} src={props.src} alt={props.alt} height={props.height} 
        onClick={props?.onClick}/>
    </div>
  )
}

export default Image