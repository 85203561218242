import { NavLink, useNavigate } from 'react-router-dom'
import Button from '../../commonComponent/Button/Button'
import './style.css'
 const ComingSoonModal=()=>{

    const navigate = useNavigate();

    return <div className='csm-out flex-column flex-centered gap-1'>

        <span>the akriti by shakun website is</span>

        <h2>COMING SOON</h2> 
        <div className="center-divider"></div>
        <div>
            <p>The website is currently being built.</p>
            <p>Until then, you can get in touch by clicking 
                <br/> the link below</p>
        </div>

        <NavLink className="link-btn" to="https://wa.link/zdwcg2">GET IN TOUCH</NavLink>
    </div>
}

export default ComingSoonModal